$greenprimary: #648459;
$greenshadow: #090c08;

.profile {
    z-index: 10;
    color: white;
}

.dark {
    background: $greenshadow;
}